:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: OpenSans;
    --alternative-font-family: Roboto, Helvetica, Arial, sans-serif;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 1.5em 0 0;
    
    /* change colors for topmenu */
    --topmenu-bg: #D81B25;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 25px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #444;
    --heading-color: var(--link-color);
    --link-color: #222;
    --link-hover-color: #000;
    --alternative-color: #ff0000;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/OpenSans.css";
@import "/fontmodules/Roboto.css";
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightgray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}


/* Header
---------------------------------------------------------*/

.topout .topcontact ul {
    list-style:none;
}

.topout .topcontact li {
    padding:0.3em 0;
}

header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:white;
    margin-right:1px;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:var(--topmenu-bg);
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown li a {
    padding:var(--topmenu-li-a-padding);
    color:#fff;
    border-right:none;
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}


/* bildspel
---------------------------------------------------------*/

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.slick, .slick-slide, .slick-substitute > div {
    height:100%;
    min-height: calc(50vh - 258px);
}

.slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.slick-slide {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: flex-start;
    justify-content: flex-start;
    height:50vh !important;
} 

.slick-title {
    color:white;
    font-size: calc(1 * var(--scale-factor-s)rem);
    width:100%;
}

.slick-title .container {
    margin:0 auto;
}

.slick-title .container .sm {
    max-width:600px;
}

.slick-title h2 {
    display:inline-block;
    padding:0.5em 1em;
    background:#D81B25;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:white;
    font-weight:700;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    margin-bottom:0;

}

.slick-title .slick-text {
    background:#20201E;
    padding:0.5em 1em;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}
.mt-2{
    margin-top:.2rem;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.block-2 .container .inner-content {
    max-width:800px;
    margin:0 auto;
    text-align:center;
    border-bottom:#eee solid 1px;
}

/* startboxar */
.startbox {
    margin-top:3em;
}

.startbox-wrapper {
    text-align:center;
}

.startbox-wrapper .pop-img svg.fa-icon {
    height:auto;
    width: 4em;
    fill: var(--alternative-color);
    vertical-align:middle !important;
}

.startbox-wrapper h2 {
    margin-top:1em;
}

.startbox-wrapper a {
    color:var(--link-color);
}

.startbox a {
    display:block;
    text-decoration:none;
}

.startbox a:hover {
    color:#6E667B;
}

.startbox-wrapper {
    padding:2em;
    border:1px solid #e8e8e8;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.startbox-wrapper:hover {
    border:1px solid #c3c3c3;
}

.pop-img img {
    display:block;
}

.readmore {
    margin-top:0.5em;
    border-top:#c3c3c3 solid 1px;
}
.readmore > div {
    padding-top:0.5em;
    color:#222;
}

.startbox-wrapper .readmore svg.fa-icon {
    height:auto;
    width: 1em;
    fill: var(--link-color);
    vertical-align:middle !important;
}
/* zoomboxar */

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .65s ease-in 0s;
    z-index: 2;
}

.zoombox .boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .65s ease-out 0s;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index:101;
    opacity:0;
    text-align:center;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
    color:white;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}


/* block-3 */
.block-3 {
    padding:1.5em 0;
}

/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}
    
/* Content
---------------------------------------------------------*/

.content h1 {
    margin-bottom:0;
    margin-top:0;
    margin-left:1%;
}

.art-content p:first-of-type {
    margin-top:0;
}

/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

.art-img {
    margin-right:0;
}

.localeswitcher.active {
    display:none;
}
    

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer h4 {
    color:white;
    font-size:1.3rem;
    letter-spacing:0;
}

footer a {
    color:white;
    text-decoration:none;
}

footer svg.fa-icon {
    fill:white;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background:#100F0F;
    border-top:#151414 solid 1px;
    padding:0.5em 0;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#565656;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

button[type=submit], input[type=submit] {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
}

button[type=submit]:hover, input[type=submit]:hover {
    background: var(--link-hover-color);
    border:var(--link-hover-color) solid 1px;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url(/extensions/local/intendit/intenditExt/web/black/map-marker.svg);
    background-size: cover;
    padding: 10px;
    background-position: center;
}
/* Sida med boxar */

.prodbox{
    box-shadow: 1px 5px 10px rgba(0,0,0,.2);
    transition:.7s ease-out .4s;
    transition-delay:.1s;
    border-radius: 5px 5px 0px 0px;
}
.boximage img{
    border-radius: 5px 5px 0px 0px;
}
.prodbox:hover{
    box-shadow: 1px 5px 10px rgba(0,0,0,.4);
    transition:.6s;
}
.p-box{
    padding:1.5rem;
}
.boxtitle{
    text-align:center;
    font-style: 1.45rem;
}
.boxtitle:after{
    height:2px;
    content:'';
    text-align:center;
    background: #C00014;
    display:block;
    margin:0 auto;
    max-width:100%;
    margin:.5rem 0;
}
a.prodbox{
    text-decoration:none;
}
.prod_desc {
    padding: 1rem;
    line-height: 1.5rem;
}
.p-box{
    text-align:center;
}
.is-teaser{
    font-weight:500;
    margin:.5rem 0;
    font-style: italic;
    font-size:1.1rem;
    display:block;
}
.imgwrapper.white.no-grow {
    display: flex;
    justify-content: center;
}
img.product-img {
    width: 300px;
    height: 200px;
    object-fit: contain;
}
.page-product-slider-nav, .page-product-slider-nav .slick-track, .product-thumb-img.slick-slide{
    max-height: 100px !important;
    overflow:hidden !important;
}


/* Media Queries
---------------------------------------------------------*/
@media (min-width: var(--breakpoint-m)) {
    .navigate {
        display:none;
    }
}

@media (max-width: var(--breakpoint-m)) {
    .menu.show, .menu {
        background:#222 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .navigate {
        margin-top:10px;
        color:white;
        font-weight:700;
        text-transform:uppercase;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        border-color:#D81B25;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }
    .slick-slide{
        height:38vh !important;
    }
.product-thumb-img.slick-slide {
    max-height: 60px !important;
}
    
}

@media (max-width: var(--breakpoint-s)) {
}

@media (max-width: var(--breakpoint-xs)) {
    footer .container {
        text-align:center;
    }
    .content h1 {
        margin-left:3%;
    }
}
